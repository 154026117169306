import React from 'react'
import PropTypes from 'prop-types'
import styles from './Footer.module.scss'

const Footer = () => (
  <div className={styles.Footer} data-testid="Footer">
    <footer>
      <div className="footer-pad">
        <div className="footer-left">
          <span>
            Trust Square AG - &copy; {new Date().getFullYear()} All Rights
            Reserved
          </span>
        </div>
        <div className="footer-right">
          <a
            href="https://modum-token-swap.atlassian.net/servicedesk/customer/portal/1"
            target="_blank"
          >
            Need help?
          </a>
          <a href="https://www.trustsquare.ch/imprint" target="_blank">
            Imprint
          </a>
        </div>
      </div>
    </footer>
  </div>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
