import { API } from 'aws-amplify'
import { Authenticator } from 'aws-amplify-react'
import { Auth } from 'aws-amplify'
import { SignUp } from 'aws-amplify-react'

/* https://dev.to/kylegalbraith/customizing-the-aws-amplify-authentication-ui-with-your-own-react-components-5fjb */
import '@aws-amplify/ui/dist/style.css'

import React, { useEffect, useState, Fragment } from 'react'
import isUserAllowedToEdit from './helpers/isUserAllowedToEdit'

import './App.css'
import './css/modum.scss'
import UserProfile from './components/UserProfile/UserProfile'
import UserMainAddress from './components/UserMainAddress/UserMainAddress'
import BankAccountNo from './components/BankAccountNo/BankAccountNo'
import NotEligible from './components/NotEligible/NotEligible'
import Footer from './components/Footer/Footer'
import { ConfidentialityAgreement } from './components/ConfidentialityAgreement/ConfidentialityAgreement'
import { CorporateDocuments } from './components/CorporateDocuments/CorporateDocuments'

function App() {
  const requestHeaderOptions = {
    headers: { 'client-identifier': 'web/modum' },
  }

  const [formMainAddress] = useState(React.createRef())
  const [formCorrespondenceAddress] = useState(React.createRef())
  const [formBankAccountNo] = useState(React.createRef())

  const [child] = useState(React.createRef())
  const [inError, setInError] = useState(false)
  const [inProgress, setInProgress] = useState(true)
  const [noUserId, setNoUserId] = useState(false)
  const [userProfileModel, setProfileModel] = useState({})
  const [isAuthanticated, setIsAuthanticated] = useState(false)
  const [isEligible, setIsEligible] = useState(null)

  const [isAccountRoute, setAccountRoute] = useState(true)
  const [isAgreed, setIsAgreed] = useState(false)

  var callOnce = false
  function respondToAuthStateChange(state, cognitoUser) {
    if (state === 'signedIn' && cognitoUser) {
      //called 3 times, need to find better way
      if (!callOnce) {
        callOnce = true
        setIsAuthanticated(true)
        callGetUserProfile()
      }
    } else {
      setInProgress(false)
      setIsAuthanticated(false)
    }
  }

  useEffect(() => {
    window.exposed_api = API
    window.exposed_auth = Auth
    window.exposed_setIsEligible = setIsEligible
    window.exposed_setInError = setInError
    const confidentialityAgreementStatus = localStorage.getItem(
      'confidentiality_agreement_confirmed'
    )
    if (confidentialityAgreementStatus === 'yes') {
      setIsAgreed(true)
    }
  }, [])

  async function displayError(err) {
    //API response like 400/403
    if (err && err.response && err.response.data && err.response.data.error) {
      setInError(err.response.data.error.message)
    }
    //Simple error object
    else if (err && err.message) {
      setInError(err.message)
    }
    //Simple message
    else if (typeof err === 'string') {
      setInError(err)
    } else {
      setInError(true)
      console.log(err)
    }
  }

  async function currentUserId() {
    var cuId = null
    var cuInfo = await Auth.currentUserInfo()
    if (cuInfo && cuInfo.attributes) {
      cuId = cuInfo.attributes.sub
    }
    return cuId
  }

  async function callGetUserProfile() {
    try {
      const userId = await currentUserId()

      setNoUserId(!userId)

      if (!userId) {
        return
      }

      setInProgress(true)
      const result = await API.get(
        'TokenSwapApiGateway',
        `/user/${userId}`,
        requestHeaderOptions
      )

      console.log('/userdata:', result)

      if (result.success) {
        setProfileModel(result.data)

        if (isUserAllowedToEdit(result.data)) {
          setIsEligible(true)
          formMainAddress.current.setModel(result.data.personalAddress || {})
          formCorrespondenceAddress.current.setModel(
            result.data.correspondenceAddress || {}
          )
          formBankAccountNo.current.setModel(
            result.data.dividentBankAccount || {}
          )
        } else {
          setIsEligible(false)
        }
      }
    } catch (err) {
      displayError(err)
    }

    setInProgress(false)
  }

  async function saveAddress(type, data) {
    userProfileModel[type] = data

    console.log('setAddress', type, data)

    setInProgress(true)

    try {
      const data = {
        id: userProfileModel.id,
        [type]: userProfileModel[type],
      }

      console.log('data', data)

      const result = await API.post('TokenSwapApiGateway', '/setAddress', {
        options: requestHeaderOptions,
        body: data,
      })
      if (result.success) {
        if (type === 'personalAddress')
          formMainAddress.current.setEditMode(false)
        if (type === 'correspondenceAddress')
          formCorrespondenceAddress.current.setEditMode(false)
        if (type === 'dividentBankAccount')
          formBankAccountNo.current.setEditMode(false)

        //TODO: assign what's returned
      } else {
        //should not occur, because errors will be thrown if no success
        displayError(true)
        console.log(result)
      }
    } catch (err) {
      displayError(err)
    }

    setInProgress(false)
  }

  return (
    <div className="App">
      <header className="top-nav">
        <a href="/" className="logo-bw">
          Home
        </a>
        {isAuthanticated && isEligible && (
          <Fragment>
            <a
              onClick={(e) => {
                e.preventDefault()
                window.location.reload()
              }}
              href="/"
              className="nav-link"
            >
              Account Information
            </a>
            <a
              onClick={(e) => {
                e.preventDefault()
                setAccountRoute(false)
              }}
              href="/"
              className="nav-link"
            >
              Corporate Documents
            </a>
          </Fragment>
        )}

        {isAuthanticated && (
          <a
            href="/"
            className="nav-link signout"
            onClick={() => Auth.signOut()}
          >
            Sign Out
          </a>
        )}
      </header>

      <div className="header-spacing"></div>

      {!isAuthanticated && (
        <div className="authenticator-wrap">
          <div className="about-app">
            <h1>Trust Square Investor Relations</h1>
            <p className="info">
              Sign in using your Modum Token Swap App credentials to access your
              account, add your bank details, and to update your correspondence
              address.
            </p>
          </div>

          <Authenticator
            onStateChange={respondToAuthStateChange}
            hide={[SignUp]}
          ></Authenticator>
        </div>
      )}

      {noUserId && (
        <div className="info-box-center">
          <div className="info-box center">
            <div className="info-box-icon">
              <svg
                id="i-alert"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                width="32"
                height="32"
                fill="none"
                stroke="currentcolor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              >
                <path d="M16 3 L30 29 2 29 Z M16 11 L16 19 M16 23 L16 25" />
              </svg>
            </div>
            <div className="info-box-content">
              PLEASE CHECK YOUR INTERNET CONNECTION AND REFRESH THIS PAGE.
            </div>
          </div>
        </div>
      )}

      {!isAccountRoute &&
        !isAgreed &&
        isAuthanticated &&
        isEligible !== null && (
          <ConfidentialityAgreement setIsAgreed={setIsAgreed} />
        )}

      {!isAccountRoute &&
        isAgreed &&
        isAuthanticated &&
        isEligible !== null && <CorporateDocuments />}

      {isAccountRoute && isAuthanticated && isEligible !== null && (
        <UserProfile model={userProfileModel} ref={child} />
      )}

      {isAccountRoute && isAuthanticated && !isEligible && (
        <NotEligible></NotEligible>
      )}

      {isAccountRoute && isAuthanticated && isEligible && (
        <BankAccountNo
          ref={formBankAccountNo}
          onError={(err) => {
            displayError(err)
          }}
          onSave={(data) => {
            saveAddress('dividentBankAccount', data)
          }}
        />
      )}

      {isAccountRoute && isAuthanticated && isEligible && (
        <UserMainAddress
          ref={formMainAddress}
          onError={(err) => {
            displayError(err)
          }}
          onSave={(data) => {
            saveAddress('personalAddress', data)
          }}
          header="Personal address"
        />
      )}

      {isAccountRoute && isAuthanticated && isEligible && (
        <UserMainAddress
          ref={formCorrespondenceAddress}
          onSave={(data) => {
            saveAddress('correspondenceAddress', data)
          }}
          onError={(err) => {
            displayError(err)
          }}
          header="Correspondence address"
          description={
            <div>
              This is the address any written communication will be sent via
              surface mail. Please add if it is different from your personal
              address.
            </div>
          }
        />
      )}

      {inError && (
        <div className="appErrorBackDrop">
          <div className="appErrorBox">
            <svg
              id="i-alert"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="32"
              height="32"
              fill="none"
              stroke="currentcolor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M16 3 L30 29 2 29 Z M16 11 L16 19 M16 23 L16 25" />
            </svg>
            {inError === true && (
              <h1>
                Oops, something went wrong. Please contact our
                <a
                  className="link"
                  href="https://modum-token-swap.atlassian.net/servicedesk/customer/portal/1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support
                </a>
                and send us a message explaining your issue.
              </h1>
            )}
            {inError !== true && <h1>{inError}</h1>}

            <a
              href="#ok"
              className="a-button"
              onClick={() => setInError(false)}
            >
              OK
            </a>
          </div>
        </div>
      )}

      {inProgress && (
        <div className="App-InProgress">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  )
}

export default App
